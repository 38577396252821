// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S5us88Y6{margin-bottom:max(2.32rem,min(3.44vw,4.13rem));padding-top:max(12.3rem,min(18.23vw,21.88rem));position:relative}@media(max-width:667.98px){.S5us88Y6{padding-top:max(3.13rem,min(10.42vw,12.5rem))}}.mGbNGdrJ{position:relative}.qdgTY67H{left:max(-64.63rem,min(-53.85vw,-36.35rem));top:max(-25rem,min(-20.83vw,-14.06rem))}.CQ7Psc3R{align-items:flex-start;display:flex;flex-wrap:wrap;gap:max(2.25rem,min(3.33vw,4rem));justify-content:flex-end;position:relative}@media(max-width:667.98px){.CQ7Psc3R{justify-content:flex-start}}.wKnM6\\+Z8{height:auto;left:0;pointer-events:none;position:absolute;top:max(-10rem,min(-8.33vw,-5.63rem));-webkit-user-select:none;-moz-user-select:none;user-select:none;width:70%;z-index:-1}@media(max-width:667.98px){.wKnM6\\+Z8{position:static;top:auto;width:100%}}.D15FZtUL{flex-basis:max(18.77rem,min(27.81vw,33.38rem))}@media(max-width:667.98px){.D15FZtUL{flex-grow:1}.D15FZtUL br{display:none}}.YzrQT9H4{font-size:max(1.72rem,min(2.55vw,3.06rem));line-height:1.06;margin-bottom:1.24em;text-transform:uppercase}.YzrQT9H4,.x-sopk5g{color:#fff;font-weight:700}.x-sopk5g{font-size:max(.75rem,min(1.15vw,1.38rem));line-height:1.3;margin-bottom:.64em}.E-Xn8lmt{font-size:max(.75rem,min(.94vw,1.13rem));font-size:max(.75rem,min(1.15vw,1.38rem));font-weight:400;line-height:1.3;padding-left:1em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "S5us88Y6",
	"section": "mGbNGdrJ",
	"fade": "qdgTY67H",
	"content": "CQ7Psc3R",
	"bg": "wKnM6+Z8",
	"text": "D15FZtUL",
	"heading": "YzrQT9H4",
	"subheading": "x-sopk5g",
	"list": "E-Xn8lmt"
};
module.exports = ___CSS_LOADER_EXPORT___;
