// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wsyKIk2\\+{margin-bottom:max(2.29rem,min(3.39vw,4.06rem))}._99GAhBjD{-moz-column-gap:max(3.52rem,min(5.21vw,6.25rem));column-gap:max(3.52rem,min(5.21vw,6.25rem));display:flex;flex-wrap:wrap;justify-content:space-between;row-gap:max(0rem,min(4.17vw,5rem))}._3nHnPbEp{flex-basis:34.56rem;flex-grow:1}.xNLMmVOM{height:max(31.25rem,min(36.67vw,44rem));margin-bottom:max(-6.25rem,min(-5.21vw,-9.38rem));-o-object-fit:contain;object-fit:contain;-o-object-position:top;object-position:top;width:auto}.xOxQV7GB{padding-left:max(0rem,min(6.25vw,7.5rem))}.eik8j7tj{color:#fff;font-size:max(.75rem,min(1.15vw,1.38rem));margin-bottom:.68em}.eik8j7tj,._6YgcXYAd{font-weight:700;line-height:1.3}._6YgcXYAd{margin-bottom:1.06em}._6YgcXYAd,.dbz0Bpnm{font-size:max(.75rem,min(.83vw,1rem))}.dbz0Bpnm{font-size:max(.75rem,min(1.04vw,1.25rem));font-weight:400;line-height:1.5}.\\+1tPut9g{display:flex;justify-content:center;padding-top:max(3.23rem,min(4.79vw,5.75rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "wsyKIk2+",
	"content": "_99GAhBjD",
	"person": "_3nHnPbEp",
	"personPhoto": "xNLMmVOM",
	"personMeta": "xOxQV7GB",
	"personName": "eik8j7tj",
	"personPosition": "_6YgcXYAd",
	"personText": "dbz0Bpnm",
	"registrationWrapper": "+1tPut9g"
};
module.exports = ___CSS_LOADER_EXPORT___;
