// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tqAXz2dh{font-size:max(.75rem,min(.94vw,1.13rem));font-weight:400;line-height:1.3;padding-top:max(3.83rem,min(5.68vw,6.81rem))}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "tqAXz2dh"
};
module.exports = ___CSS_LOADER_EXPORT___;
