
import Vue from "vue"
import { useMounted } from "@/utils/use/useMounted"

export default Vue.extend({
  layoutSettings: {
    fullWidth: true,
    darkBg: true,
    overflowHidden: true,
  },

  setup () {
    const { isMounted } = useMounted()
    return { isMounted }
  },
})
