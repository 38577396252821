// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SiNAJgEW{--default-size:max(58.57rem,min(86.77vw,104.13rem));height:max(58.57rem,min(86.77vw,104.13rem));height:var(--size,var(--default-size));-o-object-fit:contain;object-fit:contain;pointer-events:none;position:absolute;transform:scale(1.5);-webkit-user-select:none;-moz-user-select:none;user-select:none;width:max(58.57rem,min(86.77vw,104.13rem));width:var(--size,var(--default-size));z-index:-1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"fade": "SiNAJgEW"
};
module.exports = ___CSS_LOADER_EXPORT___;
