// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WC9J5UHM{margin-bottom:max(4.22rem,min(6.25vw,7.5rem))}.MZwEBYhM{position:relative}.ZapGQZMp{--size:max(40.29rem,min(59.69vw,71.63rem));right:max(-16.13rem,min(-13.44vw,-9.07rem));top:max(-14.63rem,min(-12.19vw,-8.23rem))}.e\\+IH2eRa{background:linear-gradient(0deg,#002d75,#002d75);border-radius:max(1.13rem,min(1.67vw,2rem));overflow:hidden;padding:max(2rem,min(4.32vw,5.19rem)) max(1.5rem,min(3.96vw,4.75rem)) max(2rem,min(4.58vw,5.5rem));position:relative}._9hmuYwrY{background:linear-gradient(0deg,#000f35 2.6%,#01226a 39.72%,rgba(1,24,102,0) 59.9%)}.IdlluSwF{-o-object-fit:cover;object-fit:cover;-o-object-position:top;object-position:top}._9hmuYwrY,.IdlluSwF{height:100%;left:0;pointer-events:none;position:absolute;top:0;-webkit-user-select:none;-moz-user-select:none;user-select:none;width:100%}.wqiv2Tvk{position:relative}.s-Ocq40k{align-items:flex-start;-moz-column-gap:max(2.67rem,min(3.96vw,4.75rem));column-gap:max(2.67rem,min(3.96vw,4.75rem));display:flex;flex-wrap:wrap;justify-content:space-between;row-gap:max(.56rem,min(.83vw,1rem))}.s-Ocq40k:first-of-type{margin-bottom:max(1.72rem,min(2.55vw,3.06rem))}.s-Ocq40k:first-of-type .rJLfujq\\+{color:#fff}.zVH7o2St{color:#fff;flex-basis:22.5rem;font-size:max(.75rem,min(1.15vw,1.38rem));font-size:max(.75rem,min(1.25vw,1.5rem));font-weight:700;line-height:1.3}.rJLfujq\\+{flex-basis:40rem;flex-grow:1;font-size:max(.75rem,min(.94vw,1.13rem));font-size:max(.75rem,min(1.15vw,1.38rem));font-weight:400;line-height:1.3}.rJLfujq\\+ ul{padding-left:1em}.\\+ziS1pbf{color:#fff;font-size:max(1.5rem,min(3.65vw,4.38rem));font-weight:700;line-height:.9;margin-bottom:2em;text-transform:uppercase}.b3\\+WIalX{display:flex;justify-content:center;margin-top:max(-2rem,min(-1.67vw,-1.13rem));position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorPrimary": "#001689",
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"container": "WC9J5UHM",
	"content": "MZwEBYhM",
	"fade": "ZapGQZMp",
	"card": "e+IH2eRa",
	"cardBg": "_9hmuYwrY",
	"cardBgImg": "IdlluSwF",
	"cardContent": "wqiv2Tvk",
	"cardRow": "s-Ocq40k",
	"cardRowValue": "rJLfujq+",
	"cardRowLabel": "zVH7o2St",
	"heading": "+ziS1pbf",
	"btnWrapper": "b3+WIalX"
};
module.exports = ___CSS_LOADER_EXPORT___;
