import { render, staticRenderFns } from "./index.vue?vue&type=template&id=12180412&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=12180412&prod&module=true&lang=scss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingHero: require('/app/components/landing/LandingHero.vue').default,LandingSecond: require('/app/components/landing/LandingSecond.vue').default,LandingPeople: require('/app/components/landing/LandingPeople.vue').default,LandingProgram: require('/app/components/landing/LandingProgram.vue').default})
